<template>
<div class="container">
  <div class="row">
  <h1>Quiz di {{city }}</h1> 
  <hr>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
         <form @submit.prevent="insertQuiz">
            <Multiselect ref="citiesmultiselect" v-model="cities" mode="tags"
    :searchable="true"
    :createTag="true" :options="allCities"  :required="true" @deselect="selectThisCity"></Multiselect>
            <input type="text" class="form-control" v-model="question" style="width:100%;font-size:1.2em;font-weight:bold" name="question" placeholder="inserisci qui la domanda"><br/>
            1 - <input type="text" v-model="answer_1" style="width:90%;font-size:1.2em;font-weight:bold" name="answer_1" placeholder="inserisci qui la prima risposta"><br/>
            2 - <input type="text" v-model="answer_2" style="width:90%;font-size:1.2em;font-weight:bold" name="answer_2" placeholder="inserisci qui la seconda risposta"><br/>
            3 - <input type="text" v-model="answer_3" style="width:90%;font-size:1.2em;font-weight:bold" name="answer_3" placeholder="inserisci qui la terza risposta"><br/>
            <br/><input type="text" v-model="correct_answer" style="width:90%;font-size:1.2em;font-weight:bold" name="answer_3" placeholder="inserisci qui il numero della risposta corretta (default:1)"><br/>
            <br/><button type="submit">Aggiungi nuovo Quiz</button>
            </form>
        </div>
      <div class="col-12 col-md-6">
          <h5>LISTA Quiz</h5>
          <QuizSingle v-for="el in allQuizzes" :key="el" :el="el" :question="el.question" :numAnswers="3" :city="city" :createdAt="el.createdAt">
          </QuizSingle>
      </div>
    </div>
  </div>
  </div>
</div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import QuizSingle from '../components/QuizSingle'

export default {
  name: "Quizzes",
  props:["city"],
  data () {
    return {
      cities: [this.city],
      allCities: this.$store.getters.cities
    }
  },
  computed:{
      allQuizzes: function () {
        return this.$store.getters.allQuizzes[this.city];
    },
  },
  components: {Multiselect,QuizSingle},
  created: function(){
    console.log("prelevo quiz di questa città")
      this.$store
        .dispatch("getQuizzes",this.city)
        .then((quizzesSaved) => {
          console.log("quizzes:",quizzesSaved)
        })
        .catch((error) => {
          alert(error.message);
        });
      
  },
  methods: {
    insertQuiz() {
        console.log("Sto per inserire",{cities:this.cities,question:this.question,answer_1:this.answer_1,answer_2:this.answer_2,answer_3:this.answer_3})
      this.$store
        .dispatch("insertQuiz",{cities:this.cities,question:this.question,answer_1:this.answer_1,answer_2:this.answer_2,answer_3:this.answer_3,correct_answer:this.correct_answer})
        .then(() => {
          alert("Inserito Quiz");
          this.$store
          .dispatch("getQuizzes",this.city)
          .then((quizzesSaved) => {
            console.log("quizzes:",quizzesSaved)
            this.$store.dispatch("publish",this.cities).then()
            .catch(error=>console.error(error.message))
          })
          .catch((error) => {
            alert(error.message);
           });
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    selectThisCity(event){
        console.log("What?",event)
        if (event===this.city) {
          this.$refs.citiesmultiselect.select(this.city,this.city)
        }
        this.$refs.citiesmultiselect.open()
    }
  }
  
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
