<template>
  <div class="card">
  <div class="card-body">
      <h5 class="card-title">{{title}}</h5>
    <h6 class="card-subtitle mb-2 text-muted">inserito il {{creationDate}}</h6>
    <p class="card-text">
        <span v-for="elCity in cities" class="badge rounded-pill bg-light text-dark" :key="elCity"> {{elCity}} </span>
        {{body}}
        <br/>
        {{url}}
    </p>
    <a href="#" class="card-link badge bg-danger" @click.stop="deleteNews(el.id,el.cities)">X Cancella</a>
  </div>
  </div>
</template>
<script>
export default {
  name:"NewsSingle",
  props:['city','title','body','url','cities','el',"createdAt"],
  
  data: function(){
      console.log(this.createdAt)
      return {
          creationDate:this.createdAt.toDate().toLocaleString()
      }
  },
  methods:{
      deleteNews(id,cities){
        console.log("Cancello "+id +" di ",cities)
        if (window.confirm("Confermi la cancellazione?")) {
            this.$store
            .dispatch("deleteNews",id)
            .then(() => {
                //alert("Cancellato Elemento");
                this.$store
                .dispatch("getNews",this.city)
                .then((newsSaved) => {
                    console.log("quizzes:",newsSaved)
                     this.$store.dispatch("publish",cities).then()
                     .catch(error=>console.error(error.message))
                })
                .catch((error) => {
                    alert(error.message);
                })
            })
            .catch((error) => {
               alert(error.message);
            })
        }
    }
  }
  
}
</script>
