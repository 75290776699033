<template>
<div class="container">
  <div class="row">
  <h1>NEWS di {{city }}</h1> 
  <hr>
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
         <form class="news_form" @submit.prevent="insertNews">
            <Multiselect ref="citiesmultiselect" v-model="cities" mode="tags"
    :searchable="true"
    :createTag="true" :options="allCities"  :required="true"  @deselect="selectThisCity"></Multiselect>
            <input type="text" v-model="title" style="width:100%;font-size:1.2em;font-weight:bold" name="title" placeholder="inserisci qui il titolo"><br/>
            
            <textarea v-model="body" style="width:100%;height:300px" name="body" placeholder="inserisci qui il testo"></textarea><br/>
            <input type="text" class="news_url" v-model="url" name="url" placeholder="inserisci il link (https://...)"><br/>
            <button type="submit">Aggiungi nuova News</button>
            </form>
        </div>
      <div class="col-sm-6">
          <h5>LISTA NEWS</h5>
          <NewsSingle v-for="el in allNews" :key="el" :title="el.title" :body="el.body" :el="el" :city="city" :url="el.url" :createdAt="el.createdAt">
          </NewsSingle>
          
      </div>
    </div>
  </div>
  </div>
</div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import NewsSingle from '../components/NewsSingle'

export default {
  name: "News",
  props:["city"],
  setup: function(){
      return {title:null, body:null, url:null}
  },
  data () {
    return {
      cities: [this.city],
      allCities: this.$store.getters.cities
    }
  },
  computed:{
      allNews: function () {
        console.log("notizie fresche:",this.$store.getters.allNews[this.city]);
        return this.$store.getters.allNews[this.city];
    },
  },
  components: {Multiselect,NewsSingle},
  created: function(){
    console.log("prelevo news di questa città")
      this.$store
        .dispatch("getNews",this.city)
        .then((newsSaved) => {
          console.log("news:",newsSaved)
        })
        .catch((error) => {
          alert(error.message);
        });
      
  },
  methods: {
    insertNews() {
        console.log("Sto per inserire",{cities:this.cities,title:this.title,body:this.body,url:this.url})
      this.$store
        .dispatch("insertNews",{cities:this.cities,title:this.title,body:this.body,url:this.url})//, { email: this.email, password: this.password })
        .then(() => {
          alert("Inserita News");
          this.$store
          .dispatch("getNews",this.city)
          .then((newsSaved) => {
            console.log("news:",newsSaved)
            this.$store.dispatch("publish",this.cities).then()
            .catch(error=>console.error(error.message))

          })
          .catch((error) => {
            alert(error.message);
           });
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    selectThisCity(event){
        console.log("What?",event)
        if (event===this.city) {
          this.$refs.citiesmultiselect.select(this.city,this.city)
        }
        this.$refs.citiesmultiselect.open()
    }
  }  
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
