<template>
<div>
    <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">Dashboard</li>
        <li class="breadcrumb-item active" aria-current="page">CITY</li>
        <li class="breadcrumb-item active" aria-current="page">Users</li>
        
    </ol>
    </nav>
    Da implementare, qui si potranno visualizzare i dati sugli utenti e le leaderboard.
</div>    
</template>