<template>
   <div class="card">
  <div class="card-body">
    <h5 class="card-title">{{question}}</h5>
    <h6 class="card-subtitle mb-2 text-muted">inserito il {{creationDate}}</h6>
    <p class="card-text">
        <span v-for="elCity in cities" class="badge rounded-pill bg-light text-dark" :key="elCity"> {{elCity}} </span>
        <ul class="list-group">
            <li class="list-group-item" :class = "(el.correct_answer===(1+index).toString())?'list-group-item-success':''" v-for="(answer,index) in answers" :key="answer" :index="index">{{ index +1 }} {{answer}} </li>
        </ul>
    </p>
    <a href="#" class="card-link badge bg-danger" @click.stop="deleteQuiz(el.id,el.cities)">X Cancella</a>
  </div>
</div> 
</template>
<script>
export default {
  name:"QuizSingle",
  props:['question',"el","numAnswers","createdAt","city"],
  
  data: function(){
      let answers = [...Array(this.numAnswers+1).keys()].slice(1).map((num)=>this.el["answer_"+num])
      return {
          creationDate:this.createdAt.toDate().toLocaleString(),
          answers,
          cities:this.el.cities
      }
  },
  methods:{
    deleteQuiz(id,cities){
        console.log("Cancello "+id +" di "+ this.city)
        if (window.confirm("Confermi la cancellazione?")) {
            this.$store
            .dispatch("deleteQuiz",id)
            .then(() => {
                //alert("Cancellato Quiz");
                this.$store
                .dispatch("getQuizzes",this.city)
                .then((quizzesSaved) => {
                    console.log("quizzes:",quizzesSaved)
                     this.$store.dispatch("publish",cities).then()
                     .catch(error=>console.error(error.message))
                })
                .catch((error) => {
                    alert(error.message);
                })
                //this.$router.push("/quiz/"+this.city);
            })
            .catch((error) => {
               alert(error.message);
            })
        }
    }
  }
}
</script>
